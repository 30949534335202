import React, { Component } from 'react';
import styled from 'react-emotion';
import { Link, NavLink } from 'react-router-dom';

export default class Navigation extends Component {
  state = { mobile: true };

  componentDidMount() {
    this.setBreakpoint();
    window.addEventListener('resize', () => this.setBreakpoint());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.setBreakpoint());
  }

  setBreakpoint() {
    this.setState({
      mobile: window.innerWidth <= 768,
    });
  }

  render() {
    const NavBar = styled('nav')`
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      text-align: right;
      font-size: ${(props) => props.theme.desktop.xs};
      z-index: 51;
      a {
        display: inline-block;
        padding: 7px 11px;
        line-height: 42px;
        pointer-events: auto;
        color: ${(props) => props.theme.link};
      }
      a:after {
        left: 7px !important;
        right: 7px !important;
        bottom: 11px !important;
        height: 1px !important;
      }
      a.active:after {
        transform: scale(1);
      }
      @media (max-width: 350px) {
        font-size: ${(props) => props.theme.mobile.xs};
        a {
          padding: 7px;
        }
      }
    `;

    const Container = styled('div')`
      margin-right: auto;
      margin-left: auto;
      box-sizing: border-box;
      @media (min-width: 768px) {
        width: 100%;
        padding: 0 5px;
      }
      @media (min-width: 992px) {
        width: 972px;
      }
      @media (min-width: 1200px) {
        width: 1172px;
      }
      @media (min-width: 1690px) {
        width: 1440px;
      }
    `;

    const Logo = styled('div')`
      float: left;
      font-size: ${(props) => props.theme.desktop.md};

      svg {
        width: 44px;
        height: 28px;
        fill: ${(props) => props.theme.link};
      }

      a:after,
      a:hover:after {
        display: none;
      }

      @media (max-width: 768px) {
        a {
          padding: 14px 7px 7px 7px;
          height: 28px;
        }
        .title {
          clip: rect(0, 0, 0, 0);
          clip-path: inset(50%);
          height: 1px;
          overflow: hidden;
          position: absolute;
          white-space: nowrap;
          width: 1px;
        }
      }
    `;

    return (
      <NavBar>
        <Container>
          <Logo>
            <Link to="/">
              {this.state.mobile && (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 660.7 416">
                  <path d="M298.1 0v112.9h20.5c1.8-6.4 4.9-14.6 9.4-24.5s9.7-19.2 15.5-28c5.9-8.8 12.3-16.7 19.2-23.6 6.9-6.9 13.6-10.8 20.1-11.6 8.2-1 16.8-1.6 25.8-1.9 9-.3 16.5-.4 22.6-.4h17v335.4c0 8-1.5 14.4-4.5 19.2-3 4.8-7.8 8.5-14.2 11-4.3 1.8-12.4 4.4-21.3 4.4s-16.6-4.5-21.1-7.2c-6.5-4.4-11.7-9-15.3-13.6-3.7-4.6-6.9-10.7-9.8-18.2L225.3.4h-23.1c-19.5 50.1-41.6 107.2-66.4 171.3C111 235.8 88.2 293.6 67.6 345c-4 10-8.2 17.9-12.4 23.5-4.2 5.7-10.2 11.1-17.9 16.3-4.8 3.1-11 5.5-18.5 7.4C11.3 394 5 395.1 0 395.5V416h154.4v-20.5c-15.2-.8-28.9-3.3-41-7.5-12.1-4.2-18.2-10.2-18.2-17.9 0-3.3.5-7.5 1.4-12.7s2.7-11.9 5.2-20.2c2.7-8.5 5.7-17.7 9.1-27.7 3.4-10 7.8-21.8 13.2-35.2H268l33.9 91.9c.8 1.9 1.3 4.1 1.7 6.6.4 2.5.6 4.6.6 6.4 0 4.2-5 7.8-14.9 10.7-9.9 2.9-22.4 4.8-37.4 5.8V416H577.5v-20.8c-6.3-.2-15-1.5-26.2-3.8s-18.6-4.2-22.1-5.6c-6.8-2.9-11.6-7.1-14.4-12.5-2.7-5.4-4.1-11.3-4.1-17.7V22.9h17c7.8 0 16.4.2 25.6.6 9.2.4 16.8 1 22.9 1.8 6.5 1 13.2 4.8 20.1 11.6 7 6.7 13.4 14.6 19.2 23.6 5.7 8.6 10.8 17.7 15.4 27.4s7.8 18 9.6 25.1h20.2V0H298.1zm-164 248.5l61.2-159.1 62.1 159.1H134.1z" />
                </svg>
              )}
              <span className="title">Adam Trimble</span>
            </Link>
          </Logo>
          <NavLink to="/tech">Tech</NavLink>
          <span>+</span>
          <NavLink to="/ux">UX</NavLink>
          <NavLink to="/leader">Leader</NavLink>
          <span>|</span>
          <NavLink to="/contact">Contact</NavLink>
        </Container>
      </NavBar>
    );
  }
}
