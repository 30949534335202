import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { trackingId } from './data/companyInfo.json'
import ReactGA from 'react-ga'

import { injectGlobal } from 'emotion'
import { textSize } from './data/themes.js'
import { ThemeProvider } from 'emotion-theming'

import ScrollToTop from './components/ScrollToTop.js'
import { incrementVisits } from './components/utilities.js'

import UX from './pages/UX.js'
import Contact from './pages/Contact.js'
import Home from './pages/Home.js'
import Leadership from './pages/Leadership.js'
import Technologies from './pages/Technologies.js'

injectGlobal`
  html, body {
    margin: 0;
    padding: 0;
    color: #333;
    font-family: Georgia, serif;
  }

  a {
    position: relative;
    text-decoration: none;
    color: #e8f1f2;
    pointer-events: auto;
  }

  a:after {
    position: absolute;
    display: block;
    content: ' ';
    left: 0;
    right: 0;
    bottom: 2px;
    height: 1px;
    transition: transform 150ms;
    transform: scaleX(0);
    background-color: #e8f1f2;
  }

  a:hover:after {
    transform: scaleX(1);
  }

  a:focus,
  button:focus,
  input:focus,
  textarea:focus {
    outline: 1px dotted gray;
  }
`

ReactGA.initialize(trackingId);
const Analytics = ({ location }) => {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
  return null;
}

export default class App extends Component {
  render() {
    incrementVisits()
    return (
      <Router onUpdate={this.logPageView}>
        <ThemeProvider theme={textSize}>
          <ScrollToTop>
            {/* main pages */}
            <Route path="/" component={Analytics} />
            <Route exact path="/" component={Home} />
            <Route path="/tech" component={Technologies} />
            <Route path="/ux" component={UX} />
            <Route path="/leader" component={Leadership} />
            <Route path="/contact" component={Contact} />
          </ScrollToTop>
        </ThemeProvider>
      </Router>
    );
  }
}
