function ordinalNumber(cardinalNumber) {
  var j = cardinalNumber % 10,
    k = cardinalNumber % 100;
  if (j === 1 && k !== 11) {
    return cardinalNumber + "st";
  }
  if (j === 2 && k !== 12) {
    return cardinalNumber + "nd";
  }
  if (j === 3 && k !== 13) {
    return cardinalNumber + "rd";
  }
  return cardinalNumber + "th";
}

function lightenDarkenColor(col, amt) {
  function constrain(number) {
    return Math.max(Math.min(number, 255), 0);
  }
  const color = col[0] === '#' ? col.slice(1) : col;
  const num = parseInt(color, 16);
  const r = constrain((num >> 16) + amt);
  const b = constrain(((num >> 8) & 0x00FF) + amt);
  const g = constrain((num & 0x0000FF) + amt);
  return "#" + (g | (b << 8) | (r << 16)).toString(16);
}

const getCurrentDay = () => {
  const date = new Date();
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return days[date.getDay()];
}

const getNumberOfVisits = () => {
  if (!window.localStorage) {
    return 0;
  }
  return Number(localStorage.getItem('visits')) || 0;
}

function incrementVisits() {
  if (!localStorage ||
    !sessionStorage ||
    sessionStorage.getItem('visit-start')
  ) {
    return false
  }
  sessionStorage.setItem('visit-start', new Date());
  const recordedVisits = Number(localStorage.getItem('visits'));
  localStorage.setItem('visits', recordedVisits ? recordedVisits + 1 : 0);
}

export {
  getCurrentDay,
  getNumberOfVisits,
  incrementVisits,
  ordinalNumber,
  lightenDarkenColor,
}
