import React, { Component } from 'react'
import scrollTo from 'animated-scroll-to'
import styled from 'react-emotion'
import ReactGA from 'react-ga'

const TIME_TIL_ARROWS = 750;
const ANIMATION_SPEED = 100;

export default class ScrollIcon extends Component {
  state = {
    activeArrow: 0
  }

  componentDidMount() {
    setTimeout(() => {
      this.startAnimation();
    }, TIME_TIL_ARROWS)
  }

  componentWillUnmount() {
    window.clearInterval(this.interval);
  }

  startAnimation() {
    this.interval = setInterval(
      () => this.gotoNextArrow(),
      ANIMATION_SPEED
    )
  }

  gotoNextArrow() {
    const { activeArrow } = this.state;
    if (activeArrow >= 6) {
      return window.clearInterval(this.interval);
    }
    this.setState({
      activeArrow: activeArrow + 1
    })
  }

  getArrowStyle(arrowNum) {
    const { activeArrow } = this.state;
    switch (activeArrow) {
      case 0:
        return { opacity: 0 };
      case 1:
        if (arrowNum === 1) {
          return { opacity: 0.3 };
        } else {
          return { opacity: 0 };
        }
      case 2:
        switch (arrowNum) {
          case 1:
            return { opacity: 0.2 }
          case 2:
            return { opacity: 0.3 }
          default:
            return { opacity: 0 };
        }
      case 3:
        switch (arrowNum) {
          case 1:
            return { opacity: 0.2 }
          case 2:
            return { opacity: 0.2 }
          default:
            return { opacity: 0.3 }
        }
      case 4:
        switch (arrowNum) {
          case 1:
            return { opacity: 0.05 }
          case 2:
            return { opacity: 0.2 }
          default:
            return { opacity: 0.3 }
        }
      case 5:
        switch (arrowNum) {
          case 1:
            return { opacity: 0.05 }
          case 2:
            return { opacity: 0.1 }
          default:
            return { opacity: 0.3 }
        }
      default:
        switch (arrowNum) {
          case 1:
            return { opacity: 0.05 }
          case 2:
            return { opacity: 0.1 }
          default:
            return { opacity: 0.2 }
        }
    }
  }

  scrollToNextPanel() {
    const nextPosition = window.scrollY + window.innerHeight * 1.25;
    ReactGA.event({
      category: 'User',
      action: 'Hit Scroll Button',
    })
    scrollTo(nextPosition, {
      speed: 1000,
      cancelOnUserAction: false,
    });
  }

  render() {
    const Container = styled('div')`
      position: fixed;
      padding: 11px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      bottom: 75px;
      width: 30px;
      cursor: pointer;
      pointer-events: auto;

      svg {
        fill: ${props => props.theme.text}
      }

      path {
        opacity: 0;
      }

      @media(max-width: 768px) {
        width: 24px;
        bottom: 60px;
      }
    `

    return (
      <Container onClick={this.scrollToNextPanel}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 35">
          <path style={this.getArrowStyle(1)} d="M10 7.4L3.3.6c-.7-.7-2-.7-2.7 0-.7.8-.7 2 0 2.7l8.1 8.2c.7.7 2 .7 2.7 0l8.1-8.2c.7-.8.7-2 0-2.7-.7-.7-2-.7-2.7 0L10 7.4z"/>
          <path style={this.getArrowStyle(2)} d="M10 18.9l-6.7-6.8c-.7-.7-2-.7-2.7 0-.7.8-.7 2 0 2.7L8.7 23c.7.7 2 .7 2.7 0l8.1-8.2c.7-.8.7-2 0-2.7-.7-.7-2-.7-2.7 0L10 18.9z"/>
          <path style={this.getArrowStyle(3)} d="M10 30.4l-6.7-6.8c-.7-.7-2-.7-2.7 0-.7.8-.7 2 0 2.7l8.1 8.2c.7.7 2 .7 2.7 0l8.1-8.2c.7-.8.7-2 0-2.7-.7-.7-2-.7-2.7 0L10 30.4z"/>
        </svg>
      </Container>
    )
  }
}
