import React from 'react';
import styled from 'react-emotion'
import Carousel from 'react-slick'
import { ThemeProvider } from 'emotion-theming'
import { panelColors } from '../data/themes.js'

const CaseStudy = styled('div')`
  position: relative;
  margin: 0;
  pointer-events: auto;
  color: ${props => props.theme.text};
  border-top: 1px solid ${props => props.theme.accent};
  border-bottom: 1px solid ${props => props.theme.accent};
  background-color: ${props => props.theme.background};

  div { outline: none !important; }

  a {
    color: ${props => props.theme.link};
  }

  a:after {
    height: 1px;
    background-color: ${props => props.theme.link}
  }

  .slick-dots li button:before {
    color: ${props => props.theme.text};
  }
`

const Arrow = styled('button')`
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: 0;
  background: none;
  outline: none;
  padding: 0;
  z-index: 100;

  svg {
    width: 30px;
    height: 30px;
    padding: 5px;
    fill: ${props => props.theme.text};
  }
`

const NextArrow = ({ onClick }) =>
  <Arrow aria-label="Next Slide" style={{ right: '0' }} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.9 20"><path d="M7.3 10L.5 16.7c-.7.7-.7 2 0 2.7.8.7 2 .7 2.7 0l8.2-8.1c.7-.7.7-2 0-2.7L3.2.5c-.8-.7-2-.7-2.7 0s-.7 2 0 2.7L7.3 10z"/></svg>
  </Arrow>

const PrevArrow = ({ onClick }) =>
  <Arrow aria-label="Previous Slide" style={{ left: '0' }} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 20"><path d="M4.6 9.9l6.8-6.7c.7-.7.7-2 0-2.7-.8-.7-2-.7-2.7 0L.5 8.6c-.7.7-.7 2 0 2.7l8.2 8.1c.8.7 2 .7 2.7 0s.7-2 0-2.7L4.6 9.9z"/></svg>
  </Arrow>

const sliderSettings = {
  dots: true,
  infinite: true,
  swipeAngle: false,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
}

export default ({ slides }) => (
  <ThemeProvider theme={panelColors.caseStudy}>
    <CaseStudy>
      <Carousel {...sliderSettings}>
        {slides.map((slide, index) =>
          <div key={index}>{slide}</div>
        )}
      </Carousel>
    </CaseStudy>
  </ThemeProvider>
)
