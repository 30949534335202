import React, { Component } from 'react'
import { css, keyframes } from 'react-emotion'

const bounceOut1 = keyframes`
  0%,
  100% {
            transform: rotate(-180deg) translate3d(0, 0, 0);
  }
  50% {
            transform: rotate(-180deg) translate3d(2px, 2px, 0);
  }
`

const bounceOut2 = keyframes`
  0%,
  100% {
            transform: rotate(-90deg) translate3d(0, 0, 0);
  }
  50% {
            transform: rotate(-90deg) translate3d(2px, 2px, 0);
  }
`

const bounceOut3 = keyframes`
  0%,
  100% {
            transform: rotate(0deg) translate3d(0, 0, 0);
  }
  50% {
            transform: rotate(0deg) translate3d(2px, 2px, 0);
  }
`

const bounceOut4 = keyframes`
  0%,
  100% {
            transform: rotate(90deg) translate3d(0, 0, 0);
  }
  50% {
            transform: rotate(90deg) translate3d(2px, 2px, 0);
  }
`

const bounceIn1 = keyframes`
  0%,
  100% {
            transform: rotate(-180deg) translate3d(0, 0, 0);
  }
  50% {
            transform: rotate(-180deg) translate3d(-1px, -1px, 0);
  }
`

const bounceIn2 = keyframes`
  0%,
  100% {
            transform: rotate(-90deg) translate3d(0, 0, 0);
  }
  50% {
            transform: rotate(-90deg) translate3d(-1px, -1px, 0);
  }
`

const bounceIn3 = keyframes`
  0%,
  100% {
            transform: rotate(0deg) translate3d(0, 0, 0);
  }
  50% {
            transform: rotate(0deg) translate3d(-1px, -1px, 0);
  }
`

const bounceIn4 = keyframes`
  0%,
  100% {
            transform: rotate(90deg) translate3d(0, 0, 0);
  }
  50% {
            transform: rotate(90deg) translate3d(-1px, -1px, 0);
  }
`

const toggleStyles = css`
  position: relative;
  width: 44px;
  height: 44px;
  margin: 0 auto;
  pointer-events: auto;
  cursor: pointer;

  .arrow {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    cursor: pointer;

    svg {
      display: block;
      width: 10px;
      height: 10px;
      transition: transform 100ms;
      cursor: pointer;
    }
  }

  .arrow-1 {
    top: 8px;
    left: 8px;
    transform: rotate(-180deg);
  }

  .arrow-2 {
    top: 8px;
    right: 8px;
    transform: rotate(-90deg);
  }

  .arrow-3 {
    right: 8px;
    bottom: 8px;
    transform: rotate(0deg);
  }

  .arrow-4 {
    left: 8px;
    bottom: 8px;
    transform: rotate(90deg);
  }

  &:hover {
    .arrow-1 {
      animation: ${bounceOut1} 300ms 1;
    }

    .arrow-2 {
      animation: ${bounceOut2} 300ms 1;
    }

    .arrow-3 {
      animation: ${bounceOut3} 300ms 1;
    }

    .arrow-4 {
      animation: ${bounceOut4} 300ms 1;
    }
  }

  &.active svg {
    transform: rotate3d(1, -1, 0, 180deg);
  }

  &.active:hover {
    .arrow-1 {
      animation: ${bounceIn1} 300ms 1;
    }

    .arrow-2 {
      animation: ${bounceIn2} 300ms 1;
    }

    .arrow-3 {
      animation: ${bounceIn3} 300ms 1;
    }

    .arrow-4 {
      animation: ${bounceIn4} 300ms 1;
    }
  }
`

const instructionStyle = css`
  font-size: 11px;
  font-family: monospace;
  text-align: center;
  font-weight: lighter;
`

export default class Toggle extends Component {
  constructor(props) {
    super(props);
    this.button = React.createRef();
  }
  toggle() {
    this.button.current.classList.toggle('active');
  }
  render() {
    return (
      <div>
        <div className={toggleStyles} onTouchEnd={() => this.toggle()} onClick={() => this.toggle()} ref={this.button}>
          <div className="arrow arrow-1">
            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.35 12.607H1.31c-.446 0-.81.365-.81.81v1.623c0 .446.364.81.81.81l13.728.024c.23 0 .434-.098.582-.252.154-.148.252-.353.252-.582L15.85 1.312c0-.446-.364-.81-.81-.81h-1.622c-.446 0-.81.364-.81.81v8.965l-6.86-6.86c-.39-.388-1.026-.388-1.414 0l-.88.88c-.39.39-.39 1.026 0 1.415l6.895 6.895z"></path>
            </svg>
          </div>
          <div className="arrow arrow-2">
            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.35 12.607H1.31c-.446 0-.81.365-.81.81v1.623c0 .446.364.81.81.81l13.728.024c.23 0 .434-.098.582-.252.154-.148.252-.353.252-.582L15.85 1.312c0-.446-.364-.81-.81-.81h-1.622c-.446 0-.81.364-.81.81v8.965l-6.86-6.86c-.39-.388-1.026-.388-1.414 0l-.88.88c-.39.39-.39 1.026 0 1.415l6.895 6.895z"></path>
            </svg>
          </div>
          <div className="arrow arrow-3">
            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.35 12.607H1.31c-.446 0-.81.365-.81.81v1.623c0 .446.364.81.81.81l13.728.024c.23 0 .434-.098.582-.252.154-.148.252-.353.252-.582L15.85 1.312c0-.446-.364-.81-.81-.81h-1.622c-.446 0-.81.364-.81.81v8.965l-6.86-6.86c-.39-.388-1.026-.388-1.414 0l-.88.88c-.39.39-.39 1.026 0 1.415l6.895 6.895z"></path>
            </svg>
          </div>
          <div className="arrow arrow-4">
            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.35 12.607H1.31c-.446 0-.81.365-.81.81v1.623c0 .446.364.81.81.81l13.728.024c.23 0 .434-.098.582-.252.154-.148.252-.353.252-.582L15.85 1.312c0-.446-.364-.81-.81-.81h-1.622c-.446 0-.81.364-.81.81v8.965l-6.86-6.86c-.39-.388-1.026-.388-1.414 0l-.88.88c-.39.39-.39 1.026 0 1.415l6.895 6.895z"></path>
            </svg>
          </div>
        </div>
        <div className={instructionStyle}>toggle</div>
      </div>
    );
  }
}
