import React from 'react'
import { panelColors } from '../data/themes.js'
import Panel from '../components/Panel.js'
import FullscreenToggle from '../components/FullscreenToggle.js'
import Statement from '../components/Statement.js'
import { CaseStudy, Thumbnail, Slide } from '../case-studies'
import Recommendation from '../components/Recomendation.js'
import Video from '../components/Video.js'
import Colin from '../recommenders/colin.jpg'
import Manu from '../recommenders/manu.jpg'

export default () => <div>
  <Panel theme={panelColors.eggplant} scrollIcon>
    <Statement title="ux">
      <p>The user is at the forefront of everything I build.<br />I collaborate and contribute to user research and testing in the service of usable and intuitive digital experiences.</p>
    </Statement>
  </Panel>

  <Recommendation
    author="Manu Lohiya"
    picture={Manu}
    position="Product Manager"
    quote="Adam is one of those rare people who are knowledgable across the dimensions of creative design, web development, and software development process. He is able to seamlessly transition from one aspect to another and go deep into anyone of them as needed. Adam laid the technical and creative foundation for a ground up revamp of our teletherapy platform that is now used daily by thousands of students and clinicians. It would be an honor to work with Adam again in the future."
  />

  <Panel theme={panelColors.gellato} scrollIcon>
    <Statement title="audience">
      <p>It's my goal to find, understand, and empathize with your audience segments via persona mapping, market reports, in-house data, and any other user feedback available.</p>
    </Statement>
  </Panel>

  <CaseStudy slides={[
    <Slide mobileRow early>
      <Thumbnail
        width="281"
        height="500"
        filetype="png"
        mobileSmall
        path="/presence-learning/pl-portal"
        alt="PresenceLearning clinician portal mobile" />
      <aside>
        <h2>Improving student outcomes with smart UX at PresenceLearning</h2>
        <time>August 2015</time>
        <p>When I joined the company, PresenceLearning already connected speech and occupational therapists to students in 48 continental states. After series B funding they needed to replatform out of flash while improving student outcomes. In 18 months we grew the product team from 4 to 25 and delivered a new and modern therapy platform.</p>
      </aside>
    </Slide>,
    <Slide vertical>
      <Thumbnail
        width="800"
        height="449"
        path="/presence-learning/therapy"
        alt="PresenceLearning therapy platform" />
      <aside>
        <p>After leading extensive research, testing, and iterative development cycles the PL platform was reliable and more importantly improved student progress.</p>
      </aside>
    </Slide>,
    <Slide vertical>
      <Video
        id="H0s2OR5tEQA"
        title="Live therapy session"
      />
      <aside>
        <p>We wanted to get the tech out of the way of the therapy experience so clinicians can connect to their students. Watch the video above to see a real therapy session delivered through our platform.</p>
      </aside>
    </Slide>
  ]} />

  <Panel theme={panelColors.easter} scrollIcon>
    <Statement title="possibility">
      <p>Question everything. Answers narrow possibility, and questions unveil the potential for change.</p>
      <p>I'm looking for questions that open doors.</p>
    </Statement>
  </Panel>

  <Panel theme={panelColors.allGreen} scrollIcon>
    <Statement title="prototyping">
      <p>The power of prototyping is an economy of ideas. If it's too expensive or time consuming to test your assumptions you're going to have bad solutions. Rapid prototypes and a team trained to rely on them allows the right solution to emerge from a multitude of ideas.</p>
    </Statement>
  </Panel>

  <Recommendation
    author="Colin Johnson"
    picture={Colin}
    quote="Adam is one of the most talented developers I have ever worked with. His foundation in graphic design and his sense of aesthetic coupled with his superb coding skills made him an invaluable resource to our team. His ability to rapidly code and design &#8220;on the fly&#8221; allowed us to greatly streamline our design process and deliver superior products to our clients. On top of all this, he is just a joy to work with."
  />

  <Panel theme={panelColors.italy} scrollIcon>
    <Statement title="interactions">
      <p>Every color and movement of an experience speaks to the user. I create interactions which orient the user, provide feedback to their actions, and never get in the way.</p>
      <FullscreenToggle/>
    </Statement>
  </Panel>

  <Panel theme={panelColors.cake} scrollIcon>
    <Statement title="accessibility">
      <p>Web accessibility means being an ally to all differently abled web visitors including those with vision, hearing, verbal, or intellectual disabilities. By ignoring ADA compliance a huge number companies are actively excluding a significant percentage of their users. I help companies achieve and maintain WCAG 2.0 standards.</p>
    </Statement>
  </Panel>

  <Panel theme={panelColors.eggplant}>
    <Statement>
      <p>Simplicity</p>
      <p>is about subtracting the obvious<br/>and adding the meaningful.</p>
      <cite>John Maeda</cite>
    </Statement>
  </Panel>

</div>
