import React from 'react';
import styled from 'react-emotion'
import { ThemeProvider } from 'emotion-theming'
import { panelColors } from '../data/themes.js'

const Recommendation = styled('div')`
  position: relative;
  padding: 75px 30px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: repeating-linear-gradient(
    -45deg,
    ${props => props.theme.background},
    ${props => props.theme.background} 10px,
    ${props => props.theme.accent} 10px,
    ${props => props.theme.accent} 11px
  );

  @media (max-width: 768px) {
    display: block;
    padding-bottom: 150px;
  }
`

const Picture = styled('figure')`
  flex-shrink: 0;
  width: 175px;
  height: 175px;
  margin: 0;
  border-radius: 150px;
  overflow: hidden;
  margin-right: 50px;
  img {
    width: 100%;
  }

  @media (max-width: 768px) {
    position: absolute;
    bottom: 75px;
    left: 30px;
    width: 75px;
    height: 75px;
  }
`

const Quote = styled('p')`
  color: ${props => props.theme.text};
  max-width: 500px;
  font-size: ${props => props.theme.desktop.sm};
  font-weight: lighter;
  line-height: 1.5;
  margin-top: 0;

  @media(max-width: 768px) {
    font-size: ${props => props.theme.mobile.md};
  }
`

const Author = styled('cite')`
  color: ${props => props.theme.text};
  font-size: ${props => props.theme.desktop.xs};
  font-weight: lighter;
  line-height: 1.5;

  @media(max-width: 768px) {
    position: absolute;
    left: 120px;
    bottom: 75px;
    line-height: 75px;
    font-size: ${props => props.theme.mobile.sm};
  }
`

export default ({ author, quote, picture }) => {
  return (
  <ThemeProvider theme={panelColors.recommendation}>
    <Recommendation>
      <Picture><img src={picture} alt={author}/></Picture>
      <div>
        <Quote>{ quote }</Quote>
        <Author>{ author }</Author>
      </div>
    </Recommendation>
  </ThemeProvider>
  )
}
