import React from 'react'
import styled from 'react-emotion'

export default ({
  width,
  height,
  path,
  alt,
  mobileSmall,
  filetype = "jpg",
  shadow = true,
}) => {
  const Thumbnail = styled('div')`
    position: relative;
    width: 100%;
    max-width: ${width}px;
    max-height: ${height}px;
    ${shadow && "box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.2);" }
    flex-shrink: 0;

    ${mobileSmall && `
      @media (max-width: 991px) {
        max-width: ${width/2.5}px;
        max-height: ${height/2.5}px;
      }
    `}

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `

  const Spacer = styled('div')`
    width: ${width}px;
    max-width: 100%;
    padding-bottom: ${height/width * 100}%;
    background-color: #eeeeee;
  `

  return (
    <Thumbnail>
      <img
        src={`${path}.${filetype}`}
        srcSet={`
          ${path}.${filetype} ${width}w,
          ${path}@2x.${filetype} ${width*2}w
        `}
        sizes={`(max-width: 768px) 100vw, ${width}px`}
        alt={alt} />
      <Spacer/>
    </Thumbnail>
  )
}
