import React from 'react'
import styled from 'react-emotion'

export default ({
  vertical,
  mobileReverse,
  mobileRow,
  early,
  children
}) => {
  const Container = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 40px 60px 40px;
    flex-direction: ${vertical ? 'column' : 'row'};
    font-size: ${props => props.theme.desktop.sm};
    overflow: hidden;

    img {
      max-width: 100%;
    }

    aside {
      max-width: ${vertical ? '500px' : '400px'};
      margin-left: ${vertical ? '0' : '45px'};

      h2 {
        font-size: ${props => props.theme.desktop.lg};
        margin: 0;
      }

      time {
        font-size: ${props => props.theme.desktop.xs};
        font-style: italic;
      }
    }

    @media (max-width: ${early ? '991px' : '768px'}) {
      flex-direction: ${(() => {
        if (mobileRow) { return mobileReverse ? 'row-reverse' : 'row'; }
        return mobileReverse ? 'column-reverse' : 'column';
      })()};
      font-size: ${props => props.theme.mobile.sm};

        aside {
          margin-left: ${vertical ? '0' : '20px'};

          ${!mobileRow && `
            margin-top: 2em;
            margin-left: 0;
          `}

          h2 {
            font-size: ${props => props.theme.mobile.md};
          }
        }
    }
  `

  return (
    <Container>
      {children}
    </Container>
  )
}
