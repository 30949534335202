import React from 'react'
import { render } from 'react-dom'
import App from './App'
import registerServiceWorker from './components/registerServiceWorker.js'

render(
  <App />,
  document.getElementById('root')
);
registerServiceWorker();
