import React from 'react'
import styled from 'react-emotion'

export default ({ title, id }) => {

  const Container = styled('div')`
    position: relative;
    width: 560px;

    &:after {
      content: ' ';
      display: block;
      width: 100%;
      padding-bottom: 56.4%;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    @media (min-width: 991px) {
      width: 724px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  `

  return (
    <Container>
      <iframe src={`https://www.youtube.com/embed/${id}?rel=0`} title={title} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
    </Container>
  )
}



