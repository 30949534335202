import React from 'react'
import styled from 'react-emotion'

export default class FullscreenVideo extends React.Component {

  componentWillMount() {
    this.updateWindowDimentions();
    window.addEventListener('resize', this.updateWindowDimentions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimentions.bind(this));
  }

  updateWindowDimentions() {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  }

  getVideoContainer({
    windowWidth,
    windowHeight
  }) {
    const windowAspect = windowWidth / windowHeight;
    const ASPECT_RATIO = this.props.aspect || 16 / 9;
    const styles = {};
    if (windowAspect > ASPECT_RATIO) {
      styles.width = windowWidth;
      styles.height = styles.width / ASPECT_RATIO;
      styles.left = 0;
      styles.top = (windowHeight - styles.height) / 2;
    } else {
      styles.height = windowHeight;
      styles.width = styles.height * ASPECT_RATIO;
      styles.top = 0;
      styles.left = 0;
    }
    return styled('div')`
      position: absolute;
      top: ${styles.top}px;
      left: ${styles.left}px;
      width: ${styles.width}px;
      height: ${styles.height}px;
      z-index: 1;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    `
  }

  render() {
    const VideoContainer = this.getVideoContainer(this.state);

    return (
      <VideoContainer>
        <video autoPlay muted loop playsInline>
          <source src={`background-clips/${this.props.src}`} type="video/mp4" />
        </video>
      </VideoContainer>
    )
  }
}
