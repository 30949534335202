import React from 'react';
import Panel from '../components/Panel.js'
import Statement from '../components/Statement.js'
import FullscreenVideo from '../components/FullscreenVideo.js'
import { panelColors } from '../data/themes.js'
import ReactGA from 'react-ga'

function analyticsEvent(event) {
  ReactGA.event({
    category: 'Contact Us Page',
    action: event,
  })
}

export default () =>
  <Panel fullscreen theme={panelColors.intro}>
    <FullscreenVideo src="blurred-leaves.mp4"/>
    <Statement>
      <p>
        Let's talk about<br />
        working together.
      </p>
      <p>
        <a download href="/Resume-AdamTrimble.pdf" onClick={() => analyticsEvent('Downloaded Resume')}>Download my resume</a>.<br />
        <a href="https://github.com/velomash">Browse my github</a>,<br />
        and <a href="mailto:adam.trimble@gmail.com" onClick={() => analyticsEvent('Start Email')}>send me an email</a>.
      </p>
    </Statement>
  </Panel>
