import React from 'react'
import styled from 'react-emotion'

export default ({ title, children }) => {

  const Container = styled('div')`
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 60px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    z-index: 50;
  `

  const StatementTitle = styled('h2')`
    position: absolute;
    font-size: ${props => props.theme.desktop.xl};
    height: 82px;
    line-height: 82px;
    white-space: nowrap;
    margin: 0 auto;
    left: 25px;
    top: 50%;
    margin: 0;
    padding: 0;
    transform: translate3d(-50%, -45px, 0) rotate(90deg);
    color: ${props => props.theme.accent};

    @media(max-width: 768px) {
      font-size: ${props => props.theme.mobile.xl};
      left: 15px;
    }
  `

  const StatementText = styled('div')`
    line-height: 1.25;
    font-size: ${props => props.theme.desktop.md};
    max-width: 525px;
    margin: 0 auto;

    cite {
      font-size: ${props => props.theme.desktop.sm};
    }

    @media(max-width: 768px) {
      font-size: ${props => props.theme.mobile.md};
      padding: 0 60px;

      cite {
        font-size: ${props => props.theme.mobile.sm};
      }
    }
    @media(max-width: 398px) {
      font-size: 18px;
      padding: 0 50px;
    }
  `

  return (
    <Container>
      { title && <StatementTitle>{title}</StatementTitle> }
      <StatementText>{children}</StatementText>
    </Container>
  )
}
