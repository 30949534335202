import React from 'react'
import Panel from '../components/Panel.js'
import { panelColors } from '../data/themes.js'
import Statement from '../components/Statement.js'
import { CaseStudy, Thumbnail, Slide } from '../case-studies'
import Recommendation from '../components/Recomendation.js'
import Video from '../components/Video.js'
import Ben from '../recommenders/ben.jpg'
import Hakki from '../recommenders/hakki.jpg'

export default () => <div>
  <Panel theme={panelColors.allGreen} scrollIcon>
    <Statement title="leadership">
      <p>I inspire people to do their best work by building a vision of the future together.</p>
    </Statement>
  </Panel>

  <Panel theme={panelColors.eggplant} scrollIcon>
    <Statement title="listen">
      <p>Your stakeholders and direct reports have the answers. Synthesizing that knowledge means listening for understanding not response.</p>
    </Statement>
  </Panel>

  <CaseStudy slides={[(
    <Slide mobileReverse>
      <aside>
        <h2>Redesigning Circuit: Intel's global intranet</h2>
        <time>April 2018</time>
        <p>With over 100,000 employees across nearly 50 countries, meeting the global needs of Intel doesn’t come easy. In redesigning Circuit, the company’s worldwide employee portal, Agency Inside took on that assignment. We approached the size and complexity of the task the only way we know how, methodically and thoughtfully.</p>
      </aside>
      <Thumbnail
        width="800"
        height="500"
        path="/intel-circuit/homepage-desktop"
        alt="Desktop Circuit homepage" />
    </Slide>
  ), (
    <Slide vertical>
      <Thumbnail
        width="800"
        height="553"
        path="/intel-circuit/old-circuit"
        alt="The old version of circuit" />
      <aside>
        <p>When we were tapped to update the experience in 2017 it was 9 years old and showing its age.</p>
      </aside>
    </Slide>
  ), (
    <Slide mobileRow early>
      <Thumbnail
        width="281"
        height="594"
        filetype="png"
        mobileSmall
        path="/intel-circuit/homepage-mobile"
        alt="Mobile Circuit homepage" />
      <aside>
        <h2>Complex Requirements</h2>
        <p>We gathered input from over 3,000 employees to establish, verify and prioritize an enormous set of design requirements. Circuit’s navigation, search, responsiveness, readability, and accessibility all clearly needed attention. While the requirements and audience were complex we used personalization to serve a drastically streamlined and functional design.</p>
      </aside>
    </Slide>
  ), (
    <Slide>
      <Thumbnail
        width="800"
        height="500"
        path="/intel-circuit/jobs"
        alt="Desktop Circuit jobs page" />
      <aside>
        <h2>Worldwide Stakeholders</h2>
        <p>Intel's global intranet has never been more important for its employees who use it to perform key financial, operational, and strategic parts of their jobs. Not only did we drastically improve the UX, content heirarchy, and visual design. We sheparded and persuaded a complex global group of stakeholders who all had input on the project.</p>
      </aside>
    </Slide>
  )]} />

  <Panel theme={panelColors.gellato} scrollIcon>
    <Statement title="mentorship">
      <p>I've been lucky to have the guidance of talented mentors, and as a child of educators I believe it's my personal and professional duty to pass on knowledge to as many people I can.</p>
    </Statement>
  </Panel>

  <Recommendation
    author="Ben Cato"
    picture={Ben}
    position="Front-End Developer"
    quote="I can honestly say that Adam quickly became the quintessential &#8220;Most influential and inspiring teacher in my life.&#8221; I'd never really had that type of instructor or teacher up until that point. He inspired me in many ways; not only in the knowledge and power of development, but also how passionate he is in sharing his practices and know-how..."
  />

  <Panel theme={panelColors.pinkCandy} scrollIcon>
    <Statement title="vision">
      <p>Audacious goals can open new pathways to success. The web is young. We can tell new stories on it in innovative ways.</p>
    </Statement>
  </Panel>

  <CaseStudy slides={[(
    <Slide mobileRow early>
      <Thumbnail
        width="280"
        height="500"
        mobileSmall
        path="/intel-superbowl/brady-editor"
        alt="Brady Editor" />
      <aside>
        <h2>Taking Superbowl LI from Web to Broadcast</h2>
        <time>Feb 2017</time>
        <p>We leveraged one of sport's biggest nights to showcase Intel technology. Guided by key social influencers, fans were able to draw and stamp a still frame from a 360 video of Tom Brady. Intel selected from the incredible user submissions and recombined them to form a unique fan assisted video which was so engaging it went to air as a broadcast commercial.</p>
      </aside>
    </Slide>
  ), (
    <Slide vertical>
      <Thumbnail
        width="690"
        height="478"
        path="/intel-superbowl/brady-in-progress"
        alt="in progress Brady" />
      <aside>
        <p>Superbowl level traffic requirements, complex drawing tools, and tight timelines made the project very ambitious. I successfully lead in-house and agency talent to completion on time and in budget.</p>
      </aside>
    </Slide>
  ), (
    <Slide mobileRow early>
      <Thumbnail
        width="300"
        height="500"
        shadow={false}
        mobileSmall
        path="/intel-superbowl/submissions"
        alt="user submissions" />
      <aside>
        <h2>Amazing user submissions</h2>
        <p>Intel's Agency Inside often operates in lean cross-functional teams. In the design phase I served as the key UX stakeholder.</p>
        <p>With more than 20% of unique visitors submitting a drawing it's clear our submission flow was easy and intuitive. Users really brought their incredible skill and humor to submissions.</p>
      </aside>
    </Slide>
  ), (
    <Slide vertical>
      <Video
        id="XzXf2jY8aoM"
        title="Still the #GOAT | Tom Brady Fan Video"
      />
      <aside>
        <p>Watch the broadcast spot inspired by the Superbowl app.</p>
      </aside>
    </Slide>
  )]} />

  <Panel theme={panelColors.cake} scrollIcon>
    <Statement title="relationships">
      <p>I build strong bridges from technology to the rest of the company. Creating opportunity for connection helps team members grow.</p>
    </Statement>
  </Panel>

  <Recommendation
    author="Hakkı Yemeniciler"
    picture={Hakki}
    quote="Adam is one of those illusive, mythical, over-achieving hybrid talents you hear about, read about, but never get to work with... It's not just his incredible skill at his craft that makes him a true pleasure to work with. (And believe me, his technical prowess is reliably impressive.) It is his ability to effectively use his immense set of technical tools wrapped up in uncharacteristic sensibility to design and user experience. He’s a natural born leader, extremely articulate and always calm, collected and open-minded under any stressful situation."
  />

  <Panel theme={panelColors.easter} scrollIcon>
    <Statement title="culture">
      <p>To make quality products foster a culture of passion, innovation, and collaboration where it's always safe to challenge consensus.</p>
    </Statement>
  </Panel>

  <Panel theme={panelColors.allGreen}>
    <Statement>
      <p>
        The four Cs of<br/>
        making dreams come true:<br/>
        Curiosity,<br/>
        Courage,<br/>
        Consistency,<br/>
        Confidence.
      </p>
      <cite>Walt Disney</cite>
    </Statement>
  </Panel>

</div>
