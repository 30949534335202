import React from 'react';
import { Link } from 'react-router-dom'

import Panel from '../components/Panel.js';
import Statement from '../components/Statement.js';
import { panelColors } from '../data/themes.js';
import FullscreenVideo from '../components/FullscreenVideo.js';
import { companyName, jobTitle, jobDescURL } from '../data/companyInfo.json'
import {
  ordinalNumber,
  getCurrentDay,
  getNumberOfVisits,
} from '../components/utilities.js';

const introLines = [
  <p>Happy {getCurrentDay()}! Thanks for visiting</p>,
  <p>Welcome back.</p>,
  <p>{ordinalNumber(getNumberOfVisits() + 1)} visit? I'm flattered.</p>,
  <p>Showing this page to a friend? If not you should be.</p>,
  <p>Hey, back again. Thank you.</p>,
  <p>Did you know I made my first website in 7th grade?</p>,
  <p>{ordinalNumber(getNumberOfVisits() + 1)} visit? We should have a chat.</p>,
];

export default () => (
  <Panel fullscreen theme={panelColors.intro}>
    <FullscreenVideo src="blurred-leaves.mp4" />
    <Statement>
      {introLines[Math.min(getNumberOfVisits(), introLines.length - 1)]}
      <p>
        I'm a <Link to="leader">technical leader</Link>
        <br />
        that loves <Link to="tech">clean code</Link>
        <br />
        and creating <Link to="ux">amazing experiences</Link>.
      </p>
      <p>
        <Link to="contact">Let's talk</Link>.
      </p>
    </Statement>
  </Panel>
);
