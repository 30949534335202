import React from 'react'
import styled from 'react-emotion'
import { ThemeProvider } from 'emotion-theming'
import Navigation from './Navigation.js'
import ScrollIcon from '../components/ScrollIcon.js'

export default ({ children, fullscreen, scrollIcon, theme, type }) => {
  const Panel = styled('div')`
    position: relative;
    height: ${fullscreen ? '100vh' : '125vh'};
    width: 100%;
    overflow: hidden;
    color: ${props => props.theme.text};
    background-color: ${props => props.theme.background};
    pointer-events: none;
    a {
      color: ${props => props.theme.link};
    }
    a:after {
      background-color: ${props => props.theme.link}
    }
    svg {
      fill: ${props => props.theme.text};
    }
  `

  const Clipper = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    clip-path: inset(0 0 0 0);
  `

  return (
    <ThemeProvider theme={theme}>
      <Panel>
        <Clipper>
          {children}
          {scrollIcon && <ScrollIcon />}
          <Navigation active={type} />
        </Clipper>
      </Panel>
    </ThemeProvider>
  )
}
