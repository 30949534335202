export const panelColors = {
  "intro": {
    "background": "#ffffff",
    "text": "#3b1906",
    "link": "#062f18"
  },
  "italy": {
    "background": "#f7f7ff",
    "text": "#577399",
    "accent": "#bdd5ea",
    "link": "#fe5f55"
  },
  "allGreen": {
    "background": "#001a23",
    "text": "#bdd9bf",
    "accent": "#31493c",
    "link": "#e8f1f2"
  },
  "pinkCandy": {
    "background": "#9dbf9e",
    "text": "#1b351e",
    "accent": "#f3cec9",
    "link": "#faffc2"
  },
  "beachMustard": {
    "background": "#c2a83e",
    "text": "#243e36",
    "accent": "#3c6e71",
    "link": "#e0eec6"
  },
  "gellato": {
    "background": "#f5ee9e",
    "text": "#2d728f",
    "accent": "#f49e4c",
    "link": "#ab3428"
  },
  "eggplant": {
    "background": "#2b0504",
    "text": "#a1cda8",
    "accent": "#627264",
    "link": "#ea790a"
  },
  "cake": {
    "background": "#f1c8db",
    "text": "#704c5e",
    "accent": "#df85b4",
    "link": "#558b6e"
  },
  "easter": {
    "background": "#84acce",
    "text": "#512500",
    "accent": "#d7d9b1",
    "link": "#7d1d3f"
  },
  "recommendation": {
    "background": "#181d17",
    "text": "#c5d4ca",
    "accent": "#082704"
  },
  "caseStudy": {
    "background": "#ffffff",
    "text": "#577399",
    "accent": "#f2f6f9",
    "link": "#fe5f55"
  }
}

export const textSize = {
  "mobile": {
    "xs": "14px",
    "sm": "16px",
    "md": "18px",
    "lg": "24px",
    "xl": "42px",
  },
  "desktop": {
    "xs": "16px",
    "sm": "18px",
    "md": "24px",
    "lg": "42px",
    "xl": "78px",
  }
}
